.content_wrapper {
  width: 50%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 25%;
  left: 25%;
  border: 1rem solid lightskyblue;
}

.image_wrapper {
  transition: transform 0.1s;
  box-shadow: 0px 0px 10px gray;
  background: linear-gradient(#e66465, #9198e5);
}